.t-info {
    display: flex;
}
.info-piece {
    width: 300px;
    height: 60px;
    background-color: white;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}