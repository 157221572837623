.tournament-image {
  width: 100%;
  max-height: 190px;
  margin-bottom: 10px;
}
.tournament-info {
  position: absolute;
  top: 0;
  left: 0;
  transition: 2s all ease-in;
  background-color: white;
  z-index: 5;
}
.tournament-data {
  /* height: 200px; */
}

@media only screen and (max-width: 576px) {
  .mt-10-xs {
    margin-top: 10px;
  }
}

.tournament-info.open {
  width: 100%;
  height: 177px;
}

.register-btn {
  background-color: #4DB7DF !important;
  border-color: #4DB7DF !important; 
  width: 100%;
}
.register-btn:hover {
  background-color: #197B9F !important;
  border-color: #197B9F !important;
}