table th:not(:first-child) {
  text-align: center;
}
.point-input {
  width: 40px;
  border: none;
  text-align: center;
}

.point-input:focus {
  outline: 0;
  border: none;
}
