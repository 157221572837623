* {
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  /* transition: all .2s ease; */
}
body {
  margin: 0px;
  background-color: #eeeeee;
  min-height: 100%;
}
.page {
  padding: 20px;
  float: left;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .page {
    padding: 10px;
  }
  .mob-search {
    padding: 0 5px !important;
  }
  .mob-search .page {
    padding: 20px;
  }
}
.page.inside {
  padding: 0 30px;
}
.page.side {
  padding: 0px 20px;
}
#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.visible {
  display: block;
}
.hide {
  display: none;
}
.mu {
  margin: unset !important;
}

/* Bahruz's container */
.b-container {
  width: 1200px;
  max-width: 95%;
  margin: 0 auto;
  min-height: 10px;
  position: relative;
  height: auto;
}
@media only screen and (max-width: 1200px) {
  .b-container {
    width: 1000px;
  }
}
@media only screen and (max-width: 1000px) {
  .b-container {
    width: 850px;
  }
}
@media only screen and (max-width: 800px) {
  .b-container {
    width: 750px;
  }
}
@media only screen and (max-width: 600px) {
  .b-container {
    width: 98%;
  }
}

/* Bahruz's width */
.b-100 {
  width: 100%;
}
.bh-100 {
  height: 100%;
}
.b-50 {
  width: 50%;
}
.b-25 {
  width: 25%;
  float: left;
}
.fl {
  float: left;
}

/* Bahruz's margins */
.mt30 {
  margin-top: 30px;
}
.mv20 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.mv15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.mv10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mt0 {
  margin-top: 0px;
}
.mt5 {
  margin-top: 5px;
}
.mt10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mb50 {
  margin-bottom: 50px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb5 {
  margin-bottom: 5px;
}
.mb0 {
  margin-bottom: 0;
}
/* Bahruz's paddings */
.pv15 {
  padding: 15px 0px;
}
.pv20 {
  padding: 20px 0px;
}

/* Bahruz's custom ant style */
button {
  border-radius: 2px !important;
}

/* Bahruz's floats */
.fl {
  float: left;
}
.fr {
  float: right;
}
.flex {
  display: flex;
}
.flex.center {
  justify-content: center;
  align-items: center;
}
.flex.jcsb {
  justify-content: space-between;
}
.flex.end {
  justify-content: flex-end;
}

.bold {
  font-weight: bold;
}
.p-20 {
  padding: 20px;
}
.pb-0 {
  padding-bottom: 0;
}
.mobile {
  display: none !important;
}

.no-result {
  background-color: white;
  padding: 15px;
  width: 100%;
  float: left;
}
@media only screen and (max-width: 994px) {
  .mobile {
    display: block !important;
  }
  .web {
    display: none !important;
  }
}

/* pagination */
.pagination {
  background-color: white;
  padding: 15px 0;
  margin-bottom: 20px;
}

/* soon page */
.soonpage {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.soonpage {
  background-image: url("https://scontent.fgyd6-1.fna.fbcdn.net/v/t1.0-9/73370554_2674855549257453_8510604067846750208_o.jpg?_nc_cat=105&_nc_ohc=UCsCdUwWZmcAQmOpFa16j9BXOZ1lTj9jEzREr8IzXs27E3NDVf9Uo8I0A&_nc_ht=scontent.fgyd6-1.fna&oh=210302f88eb3aafe8e49a3742fee4187&oe=5E72C55B");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding: 0;
  margin: 0;
}

.soonpage .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
}

.soonpage h1,
.soonpage h2,
.soonpage h3,
.soonpage h4,
.soonpage h5,
.soonpage h6,
.soonpage i {
  color: white;
  z-index: 2;
  padding: 0;
  margin: 0;
}

.soonpage .center {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.soonpage .centerIn {
  text-align: center;
}

.soonpage .bottom-right {
  position: absolute;
  bottom: 5%;
  right: 5%;
}

@media only screen and (max-width: 768px) {
  .soonpage .bottom-right {
    display: none;
  }
}

.soonpage .bottom-left {
  position: absolute;
  bottom: 5%;
  left: 5%;
}

.soonpage .bottom-left i {
  font-size: 20px;
  margin-right: 10px;
  border: 1px solid white;
  padding: 5px 8px;
}

.soonpage .social {
  color: white;
  margin-top: 10px;
  font-size: 20px;
}

.soonpage .social a {
  text-decoration: none;
}

.soonpage .social i {
  width: 45px;
  font-size: 25px;
  text-align: center;
}

/* color */
.bg-yellow {
  background-color: yellow;
}

/* loader */
.loaderFixed {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(81, 191, 164, 1);
  z-index: 99999;
}

/* loader */
.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

* {
  box-sizing: border-box;
}
.countdown-wrapper * {
  color: white !important;
}

h1 {
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
}

.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  color: #111;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 10px;
  padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
}

.countdown-item span {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

/* stats */
.stat-elements {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.stat-element {
  width: 48%;
  height: 50%;
  padding: 10px 10px;
  margin-bottom: 10px;
}

/* confirm element */
.result-element {
  width: auto;
  max-width: 95%;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0 50px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 420px) {
  .result-element {
    padding: 0;
  }
}

/* spinner */
.b-spinner {
  margin-left: 5px !important;
}
.b-spinner .ant-spin-dot-item {
  background-color: white;
}

/* big button */
.big-btn {
  padding: 10px;
  height: auto;
}

.position-relative {
  position: relative;
}

.vahid-liqa .group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.vahid-liqa {
  height: 80px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-position: center;
  padding: 0 20px;
  position: relative;
}
.vahid-liqa .overlay {
  width: 100%;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
}
.vahid-liqa img {
  z-index: 2;
  height: 40px;
}
.vahid-liqa #logo_for_banner {
  height: 70px;
}
.vahid-liqa h3 {
  color: white;
  z-index: 20;
  font-size: 25px;
}
.vahid-liqa button {
  background-color: transparent;
  border: none;
}

@media only screen and (max-width: 576px) {
  .vahid-liqa {
    padding: 0;
    justify-content: space-around;
  }
  .vahid-liqa h3 {
    font-size: 22px;
  }
}
