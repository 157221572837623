footer[class^="ant-layout"], header[class^="ant-layout"], section[class^="ant-layout"]{
    background-color: transparent;
    padding: 0;
    height: auto;
}

h1,h2,h3,h4,h5,h6,p,pre,small,strong,ul,ol,li{
    padding: 0;
    margin: 0;
    list-style: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}